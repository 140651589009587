import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import QuillEditor from './quill';

interface IDiaryFormProps {
  data?: IDiaryForm;
  setData?: (newData: IDiaryForm) => void;
  mutation: (data: IDiaryForm) => void;
}

export interface IDiaryForm {
  diaryId?: number;
  date: Date;
  title: string;
  content: string;
}

const DiaryForm: React.FC<IDiaryFormProps> = ({ data, setData, mutation }) => {
  const history = useHistory();

  const { control, getValues, handleSubmit, register, watch } =
    useForm<IDiaryForm>({
      defaultValues: {
        diaryId: data?.diaryId,
        title: data?.title,
        content: data?.content,
        date: data?.date ? data.date : new Date()
      }
    });

  const handlSubmit = () => {
    if (setData) setData(getValues());
    mutation(getValues());
  };

  return (
    <div className="">
      <form onSubmit={handleSubmit(handlSubmit)}>
        <Controller
          control={control}
          name="date"
          render={({ field }) => (
            <DatePicker
              placeholderText="날짜선택"
              selected={new Date(field.value)}
              onChange={(date) => {
                field.onChange(date);
              }}
              className="w-full border border-gray-200 mb-3 px-4 py-3"
              required
              disabled={Boolean(data?.diaryId)}
            />
          )}
        />
        <input
          type="text"
          placeholder="제목"
          className="w-full border border-gray-200 mb-3 px-4 py-3"
          {...register('title', { required: true })}
        />
        <div className="mb-5">
          <Controller
            control={control}
            name="content"
            render={({ field }) => (
              <QuillEditor
                value={watch('content')}
                onChange={(text) => {
                  field.onChange(text);
                }}
              />
            )}
          />
        </div>
        <button className="w-full bg-red-400 text-white mb-3 px-4 py-3">
          {data ? '수정' : '작성'}
        </button>
      </form>
    </div>
  );
};

export default DiaryForm;
