import { gql, useMutation } from '@apollo/client';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import {
  createDiaryMutation,
  createDiaryMutationVariables
} from '../../__generated__/createDiaryMutation';
import DiaryForm, { IDiaryForm } from './form';

const CREATE_DIARY_MUTATION = gql`
  mutation createDiaryMutation($input: CreateDiaryInput!) {
    createDiary(input: $input) {
      ok
      error
      diaryId
    }
  }
`;

const DiaryCreate: React.FC = () => {
  const history = useHistory();

  const [createMutation, {}] = useMutation<
    createDiaryMutation,
    createDiaryMutationVariables
  >(CREATE_DIARY_MUTATION, {
    onCompleted: ({
      createDiary: { ok, error, diaryId }
    }: createDiaryMutation) => {
      if (ok) {
        history.push(`/diary/${diaryId}`);
      } else {
        alert('오류 발생 console 참조');
        console.log(error);
      }
    }
  });

  const doCreate = (formData: IDiaryForm) => {
    createMutation({ variables: { input: { ...formData } } });
  };

  return (
    <>
      <Helmet>
        <title>일기 작성 | JM Blog</title>
      </Helmet>
      <DiaryForm mutation={doCreate} />
    </>
  );
};

export default DiaryCreate;
