import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import {
  getDiariesQuery,
  getDiariesQueryVariables
} from '../../__generated__/getDiariesQuery';

const toMoment = (year: number, month: number) => {
  return moment(`${year}-${month}`, 'YYYY-MM');
};

const GET_DIARIES_QUERY = gql`
  query getDiariesQuery($year: String!, $month: String!) {
    getDiaries(year: $year, month: $month) {
      ok
      error
      diaries {
        id
        createdAt
        updatedAt
        date
        title
        content
      }
    }
  }
`;

const DiaryIndex: React.FC = () => {
  const history = useHistory();
  const { params } = useRouteMatch<{ year: string; month: string }>();

  const [year, setYear] = useState<number>(
    params.year ? Number(params.year) : moment().year()
  );
  const [month, setMonth] = useState<number>(
    params.month ? Number(params.month) : moment().month() + 1
  );

  const { data, refetch, error } = useQuery<
    getDiariesQuery,
    getDiariesQueryVariables
  >(GET_DIARIES_QUERY, {
    variables: {
      year: toMoment(year, month).format('YYYY'),
      month: toMoment(year, month).format('MM')
    }
  });

  // 달력 업데이트시 데이터 새로 페치
  useEffect(() => {
    const updateDate = toMoment(year, month);
    refetch({
      year: updateDate.format('YYYY'),
      month: updateDate.format('MM')
    });
    history.push(`/diary/year/${year}/month/${month}`);
  }, [year, month]);

  if (error) {
    history.replace('/not-permitted');
  }

  if (!data?.getDiaries.diaries) {
    return (
      <div className="flex items-center justify-center space-x-2 animate-bounce">
        <div className="w-3 h-5 bg-blue-400 rounded-full"></div>
        <div className="w-3 h-5 bg-green-400 rounded-full"></div>
        <div className="w-3 h-5 bg-black rounded-full"></div>
      </div>
    );
  }

  const diaries = data.getDiaries.diaries.map((diary) => ({
    date: moment(diary.date).toDate(),
    title: diary.title,
    content: diary.content,
    id: diary.id
  }));

  return (
    <div className="animate-intro-y intro-y-init">
      <Helmet>
        <title>일기 | JM Blog</title>
      </Helmet>
      <div className="mb-4">
        <Month
          year={year}
          month={month}
          setYear={setYear}
          setMonth={setMonth}
        />
      </div>
      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="-my-8 divide-y-2 divide-gray-100">
            {diaries.length ? (
              diaries.map((diary) => <Day key={diary.id} {...diary} />)
            ) : (
              <div className="text-center my-8">데이터 없음</div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

/* 달력 헤더 */
interface IMonthProps {
  year: number;
  month: number;
  setYear: (year: number) => void;
  setMonth: (month: number) => void;
}

const Month: React.FC<IMonthProps> = ({ year, month, setYear, setMonth }) => {
  const lastMonth = () => {
    const prevMonthDate = moment(`${year}-${month}`, 'YYYY-MM').subtract(
      1,
      'month'
    );
    setDate(prevMonthDate);
  };

  const nextMonth = () => {
    const nextMonthDate = moment(`${year}-${month}`, 'YYYY-MM').add(1, 'month');
    setDate(nextMonthDate);
  };

  const setDate = (date: moment.Moment) => {
    setYear(date.year());
    setMonth(date.month() + 1);
  };

  return (
    <div className="px-6 flex flex-row items-center justify-between text-2xl sm:text-3xl">
      <button onClick={lastMonth} className="px-3 py-4 font-bold">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
          />
        </svg>
      </button>
      <h1 className="font-medium">
        {moment(`${year}-${month}`, 'YYYY-MM').format('YYYY-MM')}
      </h1>
      <button onClick={nextMonth} className="py-3 px-4 font-bold">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13 5l7 7-7 7M5 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
  );
};

/* 일별 렌더링 */
interface IDayProps {
  id: number;
  date: Date;
  title: string;
  content: string;
}

const Day: React.FC<IDayProps> = ({ id, date, title, content }) => {
  return (
    <Link to={`/diary/${id}`}>
      <div className="py-8 flex flex-wrap md:flex-nowrap">
        <div className="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span className="font-semibold title-font text-gray-700">일기</span>
          <span className="mt-1 text-gray-500 text-sm">
            {moment(date).format('YYYY-MM-DD')}
          </span>
        </div>
        <div className="md:flex-grow">
          <h2 className="text-2xl font-medium text-gray-900 title-font mb-2">
            {title}
          </h2>
          <p className="leading-relaxed">
            {content.replace(/<[^>]+>/g, '').substr(0, 200)}
          </p>
          <a className="text-indigo-500 inline-flex items-center mt-4">
            View
            <svg
              className="w-4 h-4 ml-2"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </a>
        </div>
      </div>
    </Link>
  );
};

export default DiaryIndex;
