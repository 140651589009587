import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DiaryIndex from '../pages/diary';
import DiaryCreate from '../pages/diary/create';
import DiaryDetail from '../pages/diary/detail';
import DiaryUpdate from '../pages/diary/update';

const DiaryRouter: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/diary/create" component={DiaryCreate} />
      <Route exact path="/diary/update/:id" component={DiaryUpdate} />
      <Route exact path="/diary/:id" component={DiaryDetail} />
      <Route exact path="/diary" component={DiaryIndex} />
      <Route
        exact
        path="/diary/year/:year?/month/:month?"
        component={DiaryIndex}
      />
    </Switch>
  );
};

export default DiaryRouter;
