import { gql, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import {
  getDiaryQuery,
  getDiaryQueryVariables
} from '../../__generated__/getDiaryQuery';
import {
  updateDiaryMutation,
  updateDiaryMutationVariables
} from '../../__generated__/updateDiaryMutation';
import { GET_DIARY_QUERY } from './detail';
import DiaryForm, { IDiaryForm } from './form';

const UPDATE_DIARY_MUTATION = gql`
  mutation updateDiaryMutation($input: UpdateDiaryInput!) {
    updateDiary(input: $input) {
      ok
      error
    }
  }
`;

const DiaryUpdate: React.FC = () => {
  const history = useHistory<IDiaryForm>();
  const data = history.location.state;

  const [updatedData, setUpdatedData] = useState<IDiaryForm>();

  const [updateMutation, { client }] = useMutation<
    updateDiaryMutation,
    updateDiaryMutationVariables
  >(UPDATE_DIARY_MUTATION, {
    onCompleted: ({ updateDiary: { ok, error } }: updateDiaryMutation) => {
      if (ok) {
        const cachedDiary = client.readQuery<
          getDiaryQuery,
          getDiaryQueryVariables
        >({
          query: GET_DIARY_QUERY,
          variables: { diaryId: Number(data.diaryId) }
        });
        if (cachedDiary?.getDiary.diary) {
          client.writeQuery<getDiaryQuery, getDiaryQueryVariables>({
            query: GET_DIARY_QUERY,
            data: {
              getDiary: {
                ...cachedDiary.getDiary,
                diary: { ...cachedDiary.getDiary.diary, ...updatedData }
              }
            }
          });
        }

        history.push(`/diary/${data.diaryId}`);
      } else {
        alert('오류 발생 console 참조');
        console.log(error);
      }
    }
  });

  const doUpdate = (formData: IDiaryForm) => {
    if (data.diaryId) {
      updateMutation({
        variables: { input: { ...formData, diaryId: data.diaryId } }
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>일기 수정 | JM Blog</title>
      </Helmet>
      <DiaryForm data={data} setData={setUpdatedData} mutation={doUpdate} />
    </>
  );
};

export default DiaryUpdate;
