import { gql, useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { useRouterParam } from '../../hooks/useRouterParam';
import {
  deleteDiaryMutation,
  deleteDiaryMutationVariables
} from '../../__generated__/deleteDiaryMutation';
import {
  getDiaryQuery,
  getDiaryQueryVariables
} from '../../__generated__/getDiaryQuery';

export const GET_DIARY_QUERY = gql`
  query getDiaryQuery($diaryId: Int!) {
    getDiary(diaryId: $diaryId) {
      ok
      error
      diary {
        id
        date
        title
        content
        createdAt
        updatedAt
      }
    }
  }
`;

const DELETE_DIARY_MUTATION = gql`
  mutation deleteDiaryMutation($diaryId: Int!) {
    deleteDiary(diaryId: $diaryId) {
      ok
      error
    }
  }
`;

const DiaryDetail: React.FC = () => {
  const history = useHistory();

  const diaryId = Number(useRouterParam(1));

  const { data, loading, error } = useQuery<
    getDiaryQuery,
    getDiaryQueryVariables
  >(GET_DIARY_QUERY, { variables: { diaryId } });

  const [deleteMutation, { client }] = useMutation<
    deleteDiaryMutation,
    deleteDiaryMutationVariables
  >(DELETE_DIARY_MUTATION, {
    variables: { diaryId: diaryId },
    onCompleted: ({ deleteDiary: { ok, error } }: deleteDiaryMutation) => {
      if (ok && data?.getDiary.diary) {
        alert('삭제되었습니다.');
        history.goBack();
      } else {
        console.log(error);
      }
    }
  });

  if (loading || !data?.getDiary.diary || error) {
    return (
      <div className="flex items-center justify-center space-x-2 animate-bounce">
        <div className="w-3 h-5 bg-blue-400 rounded-full"></div>
        <div className="w-3 h-5 bg-green-400 rounded-full"></div>
        <div className="w-3 h-5 bg-black rounded-full"></div>
      </div>
    );
  }

  const diary = data.getDiary.diary;

  return (
    <div className="max-w-screen-md mx-auto">
      <Helmet>
        <title>{diary.title} | JM Blog</title>
      </Helmet>
      <div className="flex items-center justify-between mb-12 gap-x-1 animate-intro-y intro-y-init">
        <button
          className="text-xs rounded-2xl focus:outline-none text-gray-400 px-5 py-2"
          onClick={() => {
            history.goBack();
          }}>
          &lt; Back
        </button>
        <div className="flex gap-x-1">
          <button
            className="text-xs border border-gray-200 rounded-2xl focus:outline-none text-gray-400 px-5 py-2"
            onClick={() => {
              history.push(`/diary/update/${diary.id}`, {
                ...diary,
                diaryId: diary.id
              });
            }}>
            수정
          </button>
          <button
            className="text-xs border border-gray-200 rounded-2xl focus:outline-none text-gray-400 px-5 py-2"
            onClick={() => {
              if (window.confirm('삭제하시겠습니까?')) {
                deleteMutation();
              }
            }}>
            삭제
          </button>
        </div>
      </div>

      <h1 className="sm:text-3xl text-2xl title-font font-bold mb-12 animate-intro-y intro-y-init">
        {moment(diary.date).format('YYYYMMDD')} - {diary.title}
      </h1>
      <div
        dangerouslySetInnerHTML={{ __html: diary.content }}
        className="text-gray-600 space-y-6 leading-relaxed animate-intro-y intro-y-init"
      />
    </div>
  );
};

export default DiaryDetail;
