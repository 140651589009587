import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { authTokenVar, isLoggedInVar } from '../apollo';

const Layout: React.FC = ({ children }) => {
  const history = useHistory();

  const isLoggedIn = useReactiveVar(isLoggedInVar);

  return (
    <div className="container max-w-screen-lg mx-auto space-y-12 py-6">
      <Link
        to="/"
        className="flex justify-center items-center gap-x-6 intro-x-init animate-intro-x">
        <img src="/profile.jpg" alt="jm" className="w-16 h-16 rounded-full" />
        <div className="flex flex-col gap-y-1">
          <span className="font-medium text-xl">본능적인 개발자</span>
          <div className="flex flex-row justify-end items-center gap-x-1">
            <a
              href="https://www.facebook.com/profile.php?id=100008027177233"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24">
                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z" />
              </svg>
            </a>
            <a
              href="mailto:jongmin0328@gmail.com"
              target="_blank"
              onClick={(e) => e.stopPropagation()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24">
                <path d="M12 2.02c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 12.55l-5.992-4.57h11.983l-5.991 4.57zm0 1.288l-6-4.629v6.771h12v-6.771l-6 4.629z" />
              </svg>
            </a>
          </div>
        </div>
      </Link>

      <div className="mx-auto border-b border-gray-200 w-1/2">
        {isLoggedIn && (
          <div className="pb-6 flex items-center justify-center gap-x-1 intro-x-init animate-intro-x">
            <button
              className="text-xs border border-gray-200 rounded-2xl focus:outline-none text-gray-400 px-5 py-2"
              onClick={() => {
                history.push('/diary/create');
              }}>
              Post
            </button>
            <button
              className="text-xs border border-gray-200 rounded-2xl focus:outline-none text-gray-400 px-5 py-2"
              onClick={() => {
                localStorage.removeItem('token');
                authTokenVar(null);
                isLoggedInVar(false);
                window.location.href = '/';
              }}>
              Logout
            </button>
          </div>
        )}
      </div>

      <div className="px-6">{children}</div>
    </div>
  );
};

export default Layout;
