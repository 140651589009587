import { gql, useLazyQuery } from '@apollo/client';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { authTokenVar, isLoggedInVar } from '../../apollo';
import {
  loginQuery,
  loginQueryVariables
} from '../../__generated__/loginQuery';

const LOGIN_QUERY = gql`
  query loginQuery($password: String!) {
    login(password: $password)
  }
`;

interface ILoginForm {
  password: string;
}

const Login: React.FC = () => {
  const history = useHistory();

  const { getValues, handleSubmit, register, formState } = useForm<ILoginForm>({
    mode: 'onChange'
  });

  const [loginMutation] = useLazyQuery<loginQuery, loginQueryVariables>(
    LOGIN_QUERY,
    {
      onCompleted: ({ login: token }: loginQuery) => {
        if (token) {
          localStorage.setItem('token', token);
          authTokenVar(token);
          isLoggedInVar(true);
          history.push('/diary');
        } else {
          alert('비밀번호가 정확하지 않습니다.');
        }
      }
    }
  );

  const onSubmit = () => {
    const { password } = getValues();
    loginMutation({ variables: { password } });
  };

  return (
    <div className="w-full flex flex-col justify-center items-center animate-intro-y intro-y-init">
      <Helmet>
        <title>로그인 | JM Blog</title>
      </Helmet>
      <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
        <input
          type="password"
          className="w-full border border-gray-200 mb-3 px-4 py-3"
          {...register('password', { required: '패스워드를 입력해주세요.' })}
        />
        {formState.errors.password?.message && (
          <span className="mt-1 ml-1 text-sm font-normal text-red-500">
            {formState.errors.password.message}
          </span>
        )}
        <input
          type="submit"
          value="인증"
          className={`w-full border border-gray-200 mb-3 px-4 py-3 transition-colors ${
            !formState.isValid
              ? 'bg-gray-200 text-gray-400'
              : 'bg-gradient-to-r from-purple-600 to-pink-600 text-white'
          }`}
          disabled={!formState.isValid}
        />
      </form>
    </div>
  );
};

export default Login;
