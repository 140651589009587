import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import Layout from '../layout';
import Login from '../pages/login';
import DiaryRouter from './diary-router';

const AppRouter: React.FC = () => {
  return (
    <Layout>
      <Switch>
        <Route path="/diary" component={DiaryRouter} />
        <Route path="/login" component={Login} />
        <Route
          path="/not-permitted"
          component={() => {
            return (
              <div className="flex flex-col gap-y-12 text-center animate-intro-y intro-y-init">
                <Helmet>
                  <title>Not permitted | JM Blog</title>
                </Helmet>
                <div className="text-gray-600">
                  권한이 없습니다.
                  <br />
                  This contents are private only.
                </div>
                <Link
                  to="/login"
                  className="inline-block mx-auto w-32 py-2 bg-gradient-to-r from-blue-600 to-indigo-500 text-white font-medium">
                  인증
                </Link>
              </div>
            );
          }}
        />
        <Redirect to="/diary" />
      </Switch>
    </Layout>
  );
};

export default AppRouter;
