import React from 'react';
import Editor from 'react-quill';
import '../../quill.custom.css';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [
      {
        color: [
          '#000000',
          '#e60000',
          '#ff9900',
          '#ffff00',
          '#008a00',
          '#0066cc',
          '#9933ff',
          '#ffffff',
          '#facccc',
          '#ffebcc',
          '#ffffcc',
          '#cce8cc',
          '#cce0f5',
          '#ebd6ff',
          '#bbbbbb',
          '#f06666',
          '#ffc266',
          '#ffff66',
          '#66b966',
          '#66a3e0',
          '#c285ff',
          '#888888',
          '#a10000',
          '#b26b00',
          '#b2b200',
          '#006100',
          '#0047b2',
          '#6b24b2',
          '#444444',
          '#5c0000',
          '#663d00',
          '#666600',
          '#003700',
          '#002966',
          '#3d1466'
        ]
      }
    ],
    ['link'],
    ['image', 'video']
  ]
};

const formats = [
  'color',
  'bold',
  'italic',
  'underline',
  'strike',
  'link',
  'image',
  'video'
];

interface IQuillProps {
  value: string;
  onChange: (a: string) => void;
}

const Quill: React.FC<IQuillProps> = ({
  value,
  onChange
}: {
  value: string;
  onChange: (v: string) => void;
}) => {
  return (
    <Editor
      modules={modules}
      formats={formats}
      theme="snow"
      value={value}
      onChange={onChange}
    />
  );
};

export default Quill;
