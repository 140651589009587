import {
  ApolloClient,
  InMemoryCache,
  makeVar,
  createHttpLink
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const token = localStorage.getItem('token');
export const isLoggedInVar = makeVar(Boolean(token));
export const authTokenVar = makeVar(token);

const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === 'production'
      ? 'https://jm-backend.940328.me/graphql'
      : 'http://localhost:3000/graphql'
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: authTokenVar() || ''
    }
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {}
      }
    }
  })
});
